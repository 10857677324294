<script setup lang="ts">
    import WorksiteProfitabilityDocument from '~/app/worksite/models/WorksiteProfitabilityDocument';

    const props = defineProps({
  worksiteProfitabilityDocument: null,
  size: { default: 'min-w-[100px] px-3 py-1 md:py-2 md:px-3 lg:px-5 font-medium md:font-semibold text-xsx uppercase md:normal-case md:text-[12px]' }
});

    const badgeClass = computed(() => {
        return `${props.size} ${useStatusBackgroundColour(props.worksiteProfitabilityDocument.status)}`;
    });
</script>

<template>
    <span :class="`whitespace-no-wrap inline-block text-center rounded-full leading-normal ${badgeClass}`">
        {{ $t(`worksite_profitability_document_status.${worksiteProfitabilityDocument.status}`) }}
    </span>
</template>
